<!--
 * @Description: 侧边栏
 * @Author: luocheng
 * @Date: 2022-03-28 17:10:03
 * @LastEditors: 冉桂精 156189868@qq.com
 * @LastEditTime: 2024-04-12 15:21:50
-->
<template>
  <div class="side-bar">
    <section class="base-info" v-if="userInfo">
      <img :src="userInfo.avatar || defaultAvatar" alt="" class="avatar">
      <strong class="username">{{ userInfo.name || '--' }}</strong>
      <p class="archi-name" v-if="targetArchi">{{ targetArchi.name || '--' }}</p>
    </section>
    <ul class="nav-list">
      <li class="nav-item" v-for="(item, key) in navConfig" :key="key"
        :class="{
          'active': value === key
        }"
        @click="onTab(key)"
      >
        <i :class="['iconfont', item.icon]"></i>
        <p class="label ellipsis">{{ $getLocalizedLabel({'zhLabel': item?.label, 'enLabel': item?.enLabel}) }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import eventBus from '@/plugins/eventBus';

export default {
  name: 'SideBar',
  props: {
    value: {
      type: String,
      default: 'account',
      required: true
    },
    navConfig: {
      type: Object,
      default: () => {},
      required: true
    }
  },
  data() {
    return {
      userInfo: null,
			defaultAvatar: require('@/assets/images/default-avatar.png'),
      targetArchi: null
    }
  },
  created() {
    this.userInfo = this.$GetUserInfo();
    this.targetArchi = this.$GetTargetArchi();
  },
  mounted() {
    eventBus.$on('updateUserInfo', () => {
      this.userInfo = this.$GetUserInfo();
    })
  },
  methods: {
    onTab(key) {
      this.$emit('input', key);
    }
  },
  beforeDestroy() {
    eventBus.$off('updateUserInfo');
  }
}
</script>

<style lang="less" scoped>
.side-bar{
  height: 100%;
  width: 240px;
  border-right: 1px solid #E9EBF0;
  box-sizing: border-box;
  box-sizing: border-box;
  padding: 32px 12px;
  .base-info{
    .avatar{
      margin: 0 auto;
      display: block;
      height: 40px;
      width: 40px;
      border-radius: 8px;
      object-fit: cover;
    }
    .username{
      display: block;
      margin-top: 8px;
      width: 100%;
      font-size: 14px;
      font-family: var(--systemFontFamily);
      font-weight: 500;
      color: #121619;
      line-height: 22px;
      word-break: break-all;
      text-align: center;
    }
    .archi-name{
      width: 100%;
      font-size: 12px;
      font-family: var(--systemFontFamily);
      font-weight: 400;
      color: #4D5358;
      line-height: 18px;
      text-align: center;
    }
  }
  .nav-list{
    margin-top: 24px;
    width: 100%;
    .nav-item{
      display: flex;
      width: 100%;
      height: 40px;
      border-radius: 4px;
      overflow: hidden;
      box-sizing: border-box;
      padding: 9px 12px;
      cursor: pointer;
      &.active{
        background: #E8F0FE;
      }
      .iconfont{
        width: 16px;
        height: 16px;
        line-height: 22px;
        color: #4D5358;
      }
      .label{
        margin-left: 10px;
        flex: 1;
        width: 56px;
        font-size: 14px;
        font-family: var(--systemFontFamily);
        font-weight: 400;
        color: #4D5358;
        line-height: 22px;
      }
    }
  }
}
</style>